import React,{useState} from 'react';
import "./gallery2D.css";
import desktopLandingTitle from "../../../images/desktopLandingTitle.png";
import desktopLeftBtn from "../../../images/desktopLeftBtn.png";
import desktopRightBtn from "../../../images/desktopRightBtn.png";
import image1 from "../../../images/image1.jpg";
import image2 from "../../../images/image2.jpg";
import image3 from "../../../images/image3.jpg";
import image4 from "../../../images/image4.jpg";
import image5 from "../../../images/image5.jpg";
import image6 from "../../../images/image6.jpg";
import image7 from "../../../images/image7.jpg";
import image8 from "../../../images/image8.jpg";

const defaultImages = [image1,
                       image2,
                       image3,
                       image4,
                       image5,
                       image6,
                       image7,
                       image8]

const Gallery2D = props => {

  const [imagesArray, setImagesArray] = useState(defaultImages)
  const [arrayIndex, setArrayIndex] = useState(0)

  const handleNext = () =>{
    if(arrayIndex < 3){
      let currentIndex = arrayIndex
      setArrayIndex(currentIndex + 1)
    }
  }

  const handlePrevious = () =>{
    if(arrayIndex > 0){
      let currentIndex = arrayIndex
      setArrayIndex(currentIndex - 1)
    }
  }

  const handleNextMobile = () => {
    const element = document.getElementById('mobile2D')
    if (element) {
      element.scrollBy(150,0)
    }
  }

  const handlePrevMobile = () => {
    const element = document.getElementById('mobile2D')
    if (element) {
      element.scrollBy(-150,0)
    }
  }

  return(
  <div className="gallery2D">
     <div className="headTitle" id="section2">
       PUT U ITALIJU
        <img className="desktopLandingTitle" src={desktopLandingTitle}/>
     </div>

     <div className="title2">GALERIJA</div>

     <div className="textSubtitle">
       9 talentovanih studenata iz Srbije se na putovanju kroz Italiju upoznalo sa kulturom, arhitekturom i mentalitetom
       ovog mediteranskog naroda. Uživali su u umetnosti, hrani i aperitivu. Stvorili su uspomene za čitav život i pronašli
       inspiraciju za svoje sledeće radove.
     </div>

     <div className="imageSlider">
          <div className="displayDesktop content">
                <img src={desktopLeftBtn} className="left" onClick={handlePrevious}/>

                <div src={desktopLeftBtn} className="images">
                    <img src={imagesArray[arrayIndex * 2]}/>
                    <img src={imagesArray[(arrayIndex * 2) +1]}/>
                </div>

                <img src={desktopRightBtn} className="right" onClick={handleNext}/>
          </div>

          <div className="bullets displayDesktop">
              <div className={arrayIndex===0?"bullet":"emptyBullet"}
                   onClick={()=>setArrayIndex(0)}></div>
              <div className={arrayIndex===1?"bullet":"emptyBullet"}
                   onClick={()=>setArrayIndex(1)}></div>
              <div className={arrayIndex===2?"bullet":"emptyBullet"}
                   onClick={()=>setArrayIndex(2)}></div>
              <div className={arrayIndex===3?"bullet":"emptyBullet"}
                   onClick={()=>setArrayIndex(3)}></div>
          </div>

          <div className="content displayMobile">
                <img src={desktopLeftBtn} className="left" onClick={handlePrevMobile}/>

                <div src={desktopLeftBtn} className="images" id="mobile2D">
                    {imagesArray.map((image,i)=>(
                      <img src={image}
                           key={i}
                           />
                    ))}

                </div>

                <img src={desktopRightBtn} className="right" onClick={handleNextMobile}/>
          </div>
     </div>

  </div>
)};

export default Gallery2D;
