import React from 'react';
import "./art.css";
import artsArray from './artsJSON';

const Art = props => {

  const handleClick = async () => {
      props.handleCurrentArt(artsArray[props.ID - 1])
      props.togglePopup(2)
  }

  return (
    <div className="art" style={{ transform: `translateX(${props.transition}px` }}>
      <img src={`https://api.iframe-umetnost-rs.birramoretti.com/arts/${props.image}`} className='artImg' onClick={handleClick} />
      <div className={props.firstName == 'Marta' || props.firstName == 'Lana' ? 'shortName' : "name"}>{props.firstName.toUpperCase() + " " + props.lastName.toUpperCase()}</div>
      <div className="info">{props.name} <br /> {props.technique}</div>
      <div className="btn">GLASAJ</div>
    </div>
  )
};

export default Art;
