import React, { useState, useEffect } from 'react';
import "./artists.css";
import desktopLandingTitle from "../../../images/desktopLandingTitle.png";
import Artist from "./artist.jsx";
import artistsArray from './artistsJSON.js';

const Artists = props => {

  return (
    <div className="artists">
      <div className="headTitle" id="section3">
        UPOZNAJTE UMETNIKE
        <img className="desktopLandingTitle" src={desktopLandingTitle} />
      </div>

      <div className="textSubtitle">
        Naši umetnici će nam se kasnije predstaviti svojim radovima inspirisanim Italijom, a sada će, u nekoliko
        rečenica, reći ono najosnovnije i najvažnije o sebi. Poslušajte ih, kako biste na pravi način doživeli njihovu
        umetnost.
      </div>

      <div className="content">
        {artistsArray.map(artist => (
          <Artist key={artist.id}
            firstName={artist.firstName}
            lastName={artist.lastName}
            faculty={artist.faculty}
            city={artist.city}
            profile={artist.profileVideo}
            image={artist.profileImage}
            togglePopup={props.togglePopup}
            handleCurrentVideo={props.handleCurrentVideo}
          />
        ))}
      </div>
    </div>
  )
};

export default Artists;
