import React,{useEffect} from 'react';
import "./artistPopup.css";
import closeIcon from "../../images/closeIcon.png";

const ArtistPopup = props => {

  const handleClose = e => {

      props.togglePopup(0)
      props.handleCurrentVideo("")
      props.togglePopup(0)
      setTimeout(() => {
                        document.getElementById("section3").scrollIntoView({behavior: "smooth"});
                      }, 300);
  }

  useEffect(()=>{
    document.getElementById("artistTitle").scrollIntoView({ behavior: "smooth", block: "start" });
  },[])

  return (
    <div className="artistPopup" id="artistTitle">
      <div className="container">
        <video className="video" controls autoPlay>
          <source src={`https://api.iframe-umetnost-rs.birramoretti.com/profile/${props.currentVideo}`} type="video/mp4" />

        </video>

        <div className="close" onClick={handleClose}>
          <img src={closeIcon} />
        </div>
      </div>
    </div>
  )
};

export default ArtistPopup;
