 const artistsArray = [
    {
        "id": 1,
        "firstName": "Jana",
        "lastName": "Panajotović",
        "faculty": "Akademija umetnosti",
        "city": "Novi Sad",
        "profileImage": "jana-panajotovic.jpg",
        "profileVideo": "profile-jana-panajotovic.mp4"
    },
    {
        "id": 2,
        "firstName": "Anja",
        "lastName": "Preradović",
        "faculty": "Akademija umetnosti",
        "city": "Novi Sad",
        "profileImage": "anja-preradovic.jpg",
        "profileVideo": "profile-anja-preradovic.mp4"
    },
    {
        "id": 3,
        "firstName": "Lana",
        "lastName": "Katić",
        "faculty": "Akademija umetnosti",
        "city": "Novi Sad",
        "profileImage": "lana-katic.jpg",
        "profileVideo": "profile-lana-katic.mp4"
    },
    {
        "id": 4,
        "firstName": "Aleksandar",
        "lastName": "Smederevac",
        "faculty": "Fakultet likovnih umetnosti",
        "city": "Beograd",
        "profileImage": "aleksandar-smederevac.jpg",
        "profileVideo": "profile-aleksandar-smederevac.mp4"
    },
    {
        "id": 5,
        "firstName": "Ksenija",
        "lastName": "Popović",
        "faculty": "Fakultet likovnih umetnosti",
        "city": "Beograd",
        "profileImage": "ksenija-popovic.jpg",
        "profileVideo": "profile-ksenija-popovic.mp4"
    },
    {
        "id": 6,
        "firstName": "Dunja",
        "lastName": "Ćorlomanović",
        "faculty": "Fakultet likovnih umetnosti",
        "city": "Beograd",
        "profileImage": "dunja-corlomanovic.jpg",
        "profileVideo": "profile-dunja-corlomanovic.mp4"
    },
    {
        "id": 7,
        "firstName": "Marta",
        "lastName": "Pejčić",
        "faculty": "Fakultet umetnosti",
        "city": "Niš",
        "profileImage": "marta-pejcic.jpg",
        "profileVideo": "profile-marta-pejcic.mp4"
    },
    {
        "id": 8,
        "firstName": "Jovana",
        "lastName": "Milojević",
        "faculty": "Fakultet umetnosti",
        "city": "Niš",
        "profileImage": "jovana-milojevic.jpg",
        "profileVideo": "profile-jovana-milojevic.mp4"
    },
    {
        "id": 9,
        "firstName": "Jana",
        "lastName": "Novaković",
        "faculty": "Fakultet umetnosti",
        "city": "Niš",
        "profileImage": "jana-novakovic.jpg",
        "profileVideo": "profile-jana-novakovic.mp4"
    }
]

export default artistsArray