const { api } = require('./index');
const registerUser = async (firstName, lastName, email, artId) => {
  const response = await fetch(`${api}/users/register`, {
    method: 'POST',
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      artId
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  const result = response.json();
  return result;
}

export default registerUser;
