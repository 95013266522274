import React from 'react';
import "./gallery3D.css";
import desktopLandingTitle from "../../../images/desktopLandingTitle.png";
import banner from "../../../images/banner.jpg";

const Gallery3D = props => (
  <div className="gallery3D">
    <div className="headTitle" id="section5">
      VIRTUELNA IZLOŽBA
      <img className="desktopLandingTitle" src={desktopLandingTitle} />
    </div>

    {/* <img src={banner} className="iframe" /> */}

    <iframe className="iframe" src="//storage.net-fs.com/hosting/6500037/18/" name="Galerija 360 VT" width="100%" height="100%" frameborder="0" allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe>

    <div className="textSubtitle">
      Nadamo se da ste kroz umetnost doživeli Italiju na pravi način. Hvala vam na izdvojenom vremenu i vašim glasovima.
      <br />
      Vidimo se na nekoj od izložbi.
    </div>

  </div>
);

export default Gallery3D;
