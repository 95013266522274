import React from 'react';
import { Link } from 'react-scroll';
import "./header.css";

const Header = props => {

  const handleClick = name =>{
    if(props.activePopup === 0){
      document.getElementById(name).scrollIntoView({behavior: "smooth"});
    }
    else{
      props.togglePopup(0)
      setTimeout(() => {
                        document.getElementById(name).scrollIntoView({behavior: "smooth"});
                      }, 300);
    }

  }

  return(
  <header id="header">
      <div onClick={()=>props.togglePopup(0)}>POČETNA</div>
      <div onClick={() => handleClick("section2")}>PUT U ITALIJU</div>
      <div onClick={() => handleClick("section3")}>UPOZNAJ UMETNIKE</div>
      <div onClick={() => handleClick("section4")}>GLASAJ</div>
      <div onClick={() => handleClick("section5")}>VIRTUELNA IZLOŽBA</div>
  </header>
)};

export default Header;
