const artsArray = [
    {
        "firstName": "Jana",
        "lastName": "Panajotović",
        "artId": 1,
        "name": "Sara perche ti amo",
        "artTechnique": "Ulje na platnu ",
        "image": "jana-panajotovic-web.jpg"
    },
    {
        "firstName": "Anja",
        "lastName": "Preradović",
        "artId": 2,
        "name": "L'Italiano vero",
        "artTechnique": "Akril na platnu",
        "image": "anja-preradovic-web.jpg"
    },
    {
        "firstName": "Lana",
        "lastName": "Katić",
        "artId": 3,
        "name": "Stvaranje lepih trenutaka",
        "artTechnique": "Digitalna slika",
        "image": "lana-katic-web.jpeg"
    },
    {
        "firstName": "Aleksandar",
        "lastName": "Smederevac",
        "artId": 4,
        "name": "Veselo u Toskani",
        "artTechnique": "Ulje na platnu",
        "image": "aleksandar-smederevac-web.jpg"
    },
    {
        "firstName": "Ksenija",
        "lastName": "Popović",
        "artId": 5,
        "name": "Holy Birra",
        "artTechnique": "Akril na platnu",
        "image": "ksenija-popovic-web.jpg"
    },
    {
        "firstName": "Dunja",
        "lastName": "Ćorlomanović",
        "artId": 6,
        "name": "Kompozicija sa gurmanlukom i ostalim užicima",
        "artTechnique": "Akril na platnu",
        "image": "dunja-corlomanovic-web.jpg"
    },
    {
        "firstName": "Marta",
        "lastName": "Pejčić",
        "artId": 7,
        "name": "Way of life",
        "artTechnique": "Digitalni kolaž",
        "image": "marta-pejcic-web.jpeg"
    },
    {
        "firstName": "Jovana",
        "lastName": "Milojević",
        "artId": 8,
        "name": "Ukus Italije",
        "artTechnique": "Digitalna ilustracija",
        "image": "jovana-milojevic-web.jpeg"
    },
    {
        "firstName": "Jana",
        "lastName": "Novaković",
        "artId": 9,
        "name": "Vožnja sa Morretijem",
        "artTechnique": "Digitalna ilustracija",
        "image": "jana-novakovic-web.jpeg"
    }
]

export default artsArray