import React, { useState, useEffect } from 'react';
import "./votePage.css";
import desktopLandingTitle from "../../images/desktopLandingTitle.png";
import brush1 from "../../images/brush1.png";
import iconProfile from "../../images/iconProfile.png";
import iconMail from "../../images/iconMail.png";
import registerUser from "../../API/registerUser.js";
import closeIconRed from "../../images/closeIconRed.png";

const VotePage = props => {

  const mailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  const stringRegex = /^[a-zA-ZšŠđĐžŽčČćĆабвгдђежзијклљмнњопрстћуфхцчџшАБВГДЂЕЖЗИЈКЛЉМНЊОПРСТЋУФХЦЧЏШ]+$/

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [isValidated, setIsValidated] = useState("")

  const [isBlured, setIsBlured] = useState({
    "firstName": "",
    "lastName": "",
    "email": ""
  })

  const [isFocused, setIsFocused] = useState({
    "firstName": "",
    "lastName": "",
    "email": ""
  })


  const handleEmail = e => setEmail(e.target.value)
  const handleFirstName = e => setFirstName(e.target.value)
  const handleLastName = e => setLastName(e.target.value)

  const handleClose = () => {
    if (props.activePopup === 0) {
      document.getElementById("section4").scrollIntoView({ behavior: "smooth" });
    }
    else {
      props.togglePopup(0)
      setTimeout(() => {
        document.getElementById("section4").scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }

  const handleBlur = (e) => {

    let copyBlur = { ...isBlured }
    let copyFocused = { ...isFocused }
    // Change state wheh input is blured
    copyBlur[e.target.name] = true
    setIsBlured(copyBlur)

    // After blur element will defocus so we change state here
    copyFocused[e.target.name] = false
    setIsFocused(copyFocused)
  }

  const handleFocus = (e) => {
    let copy = { ...isFocused }
    copy[e.target.name] = true
    setIsFocused(copy)
  }

  const handleSubmit = async () => {
    props.handleApproveMessage("")
    if (isValidated === "true") {
      let response = await registerUser(firstName, lastName, email, props.voteID)
      if (response.success) {
        props.togglePopup(4)
      }
      else if (response.error) {
        setErrMsg(response.error.message)

        setTimeout(() => {
          setErrMsg("")
        }, "5000");
      }
    }

    else {
      setIsBlured({
        "firstName": true,
        "lastName": true,
        "phone": true,
        "mail": true,
        "city": true
      })
    }

  }

  useEffect(() => {
    if (firstName.length < 1 || !stringRegex.test(firstName) ||
      lastName.length < 1 || !stringRegex.test(lastName) ||
      !mailRegex.test(email)) {
      setIsValidated("false")
    }
    else {
      setIsValidated("true")
    }
  }, [firstName, lastName, email])

  useEffect(() => {
    document.getElementById("voteTitle").scrollIntoView({ behavior: "smooth", block: "start" });
  }, [])

  return (
    <div className="votePage" id="votePage">

      <div className="close" onClick={handleClose}>
        <img src={closeIconRed} className='closeIcon' />
      </div>

      <div className="headTitle" id="voteTitle">
        GLASAJ
        <img className="desktopLandingTitlee" src={desktopLandingTitle} />
      </div>

      <div className="textSubtitle">
        Sa jedne e-mail adrese se može samo jednom glasati. E-mail adresu ostavljate isključivo radi verifikacije i ona se
        ne čuva u sistemu, izuzev za potrebe sprečavanja ponovnog glasanja.
      </div>

      <div className="form">
        <div className="inputHolder" inputErr={(isBlured.firstName && (firstName.length < 1 || !stringRegex.test(firstName))) ? "true" : ""}>
          <img src={iconProfile} />
          <input type="text"
            name="firstName"
            placeholder="Ime"
            value={firstName}
            onChange={handleFirstName}
            onBlur={(e) => handleBlur(e)}
            onFocus={(e) => handleFocus(e)} />
        </div>

        <div className="inputHolder" inputErr={(isBlured.lastName && (lastName.length < 1 || !stringRegex.test(lastName))) ? "true" : ""}>
          <img src={iconProfile} />
          <input type="text"
            name="lastName"
            placeholder="Prezime"
            value={lastName}
            onChange={handleLastName}
            onBlur={(e) => handleBlur(e)}
            onFocus={(e) => handleFocus(e)} />
        </div>

        <div className="inputHolder" inputErr={(isBlured.mail && !mailRegex.test(email)) ? "true" : ""}>
          <img src={iconMail} />
          <input type="text"
            name="mail"
            placeholder="e-mail"
            value={email}
            onChange={handleEmail}
            onBlur={(e) => handleBlur(e)}
            onFocus={(e) => handleFocus(e)} />
        </div>

        <div className="btn" onClick={() => alert('Glasanje je završeno!')}>GLASAJ</div>

        {(isValidated === "false" || errMsg) && <div className="err">
          {(isBlured.firstName && (firstName.length < 1 || !stringRegex.test(firstName))) ? (<p>* Ime je obavezno i mora sadržati samo slova </p>) : ""}

          {(isBlured.lastName && (lastName.length < 1 || !stringRegex.test(lastName))) ? (<p>* Prezime je obavezno i mora sadržati samo slova </p>) : ""}

          {(isBlured.mail && !mailRegex.test(email)) ? (<p>* Email je obavezan i mora biti u ispravnom formatu </p>) : ""}

          {errMsg ? (<p> {errMsg}</p>) : ""}
        </div>}
      </div>

      <img src={brush1} className="brush1" />
    </div>
  )
};

export default VotePage;
