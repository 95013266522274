import React, { useState, useEffect } from "react";
import "./arts.css";
import desktopLandingTitle from "../../../images/desktopLandingTitle.png";
import desktopLeftBtn from "../../../images/desktopLeftBtn.png";
import desktopRightBtn from "../../../images/desktopRightBtn.png";
import Art from "./art.jsx";
import artsArray from "./artsJSON.js";

const Arts = (props) => {
  const [transition, setTransition] = useState(0);

  const handleNext = () => {
    const element = document.getElementById("artScroller");
    if (element) {
      element.scrollBy(278, 0);
    }
  };

  const handlePrev = () => {
    const element = document.getElementById("artScroller");
    if (element) {
      element.scrollBy(-278, 0);
    }
  };

  return (
    <div className="arts">
      <div className="headTitle" id="section4">
        RADOVI UMETNIKA
        <img className="desktopLandingTitle" src={desktopLandingTitle} />
      </div>

      <div className="textSubtitle">
        Naši talentovani studenti su u Italiji pronašli inspiraciju i stvorili
        nova umetnička dela. Pogledajte ih i prenesite nam utiske, tako što ćete
        glasati za svog favorita.
        <br />
        Glasanje traje od 29.05. do 02.07.2023, a na osnovu broja pristiglih
        glasova i uz pomoć stručnog žirija, utvrdićemo pobednika našeg „Doživi
        Italiju kroz umetnost“ konkursa. Studenta sa najboljim radom očekuje
        sjajna nagrada.
      </div>

      <div className="content">
        <div src={desktopLeftBtn} className="images" id="artScroller">
          {artsArray.map((art) => (
            <Art
              key={art.artId}
              ID={art.artId}
              firstName={art.firstName}
              lastName={art.lastName}
              image={art.image}
              name={art.name}
              technique={art.artTechnique}
              transition={transition}
              togglePopup={props.togglePopup}
              handleCurrentArt={props.handleCurrentArt}
              handleVoteID={props.handleVoteID}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Arts;
