import React, { useEffect } from 'react';
import "./artPopup.css";
import image1 from "../../images/image1.jpg";
import closeIconRed from "../../images/closeIconRed.png";

const ArtPopup = props => {
  const handleClose = e => {
    props.togglePopup(0)
    setTimeout(() => {
      document.getElementById("section4").scrollIntoView({ behavior: "smooth" });
    }, 300);
  }

  useEffect(() => {
    document.getElementById("artTitle").scrollIntoView({ behavior: "smooth", block: "start" });
  }, [])



  return (
    <div className="artPopup" id="artTitle">

      <div className="container">
        <img src={`https://api.iframe-umetnost-rs.birramoretti.com/arts/${props.currentArt.image}`} />

        <div className="content">
          <div className="name">{props.currentArt.firstName + " " + props.currentArt.lastName}</div>
          <div className="title">{props.currentArt.name} <br /> {props.currentArt.artTechnique}</div>
          <div className="btn">GLASAJ</div>
        </div>

        <div className="close" onClick={handleClose}>
          <img src={closeIconRed} className='closeIconArt' />
        </div>
      </div>



    </div>
  )
};

export default ArtPopup;
