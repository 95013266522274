import React from "react";
import "./welcomePart.css";
import desktopWelcomeBrush from "../../../images/desktopWelcomeBrush.png";
import desktopWelcomeWhiteBrush from "../../../images/desktopWelcomeWhiteBrush.png";
import desktopWelcomeFlag from "../../../images/desktopWelcomeFlag.png";
import desktopWelcomeRestrict from "../../../images/desktopWelcomeRestrict.png";
import desktopWelcomeLogo from "../../../images/desktopWelcomeLogo.png";
import desktopWelcomeCan from "../../../images/desktopWelcomeCan.png";
import desktopWelcomeIcons from "../../../images/desktopWelcomeIcons.png";
import mobileWelcomeBrush from "../../../images/mobileWelcomeBrush.png";
import { Link } from "react-scroll";

const WelcomePart = (props) => (
  <div className="welcome">
    <div className="container">
      <div className="text">
        <div className="title">
          DOŽIVI ITALIJU <br className="disableDesktop" /> KROZ UMETNOST
        </div>
        <div className="subtitle">
          Poseti neku od izložbi gde ćemo predstaviti radove naših studenata
          inspirisane Italijom i glasaj za svog favorita na sajtu
        </div>
        <div className="info disableDesktop">
          Glasanje će trajati od 29.05. do 02.07.2023. a najbolji rad osvaja
          sjajnu nagradu
        </div>
        <div className="mobileInfo">
          Glasanje će trajati od 29.05. do 02.07.2023. a najbolji rad osvaja
          sjajnu nagradu
        </div>

        <div className="whiteBrushContainer">
          <img
            className="desktopWelcomeWhiteBrush disableDesktop"
            src={desktopWelcomeWhiteBrush}
          />
          <div className="date">
            Beograd <br />
            TC Galerija <br />
            05.06 – 11.06.
          </div>

          <div className="date">
            Novi Sad <br />
            TC Promenada <br />
            13.06 – 18.06.
          </div>

          <div className="date">
            Niš <br />
            TC Delta Planet <br />
            20.06 – 25.06.
          </div>
        </div>
      </div>

      <img className="mobileWelcomeBrush" src={mobileWelcomeBrush} />
      <img className="desktopWelcomeBrush" src={desktopWelcomeBrush} />
      <img className="desktopWelcomeFlag" src={desktopWelcomeFlag} />
      <img className="desktopWelcomeRestrict" src={desktopWelcomeRestrict} />
      <img className="desktopWelcomeLogo" src={desktopWelcomeLogo} />
      <img className="desktopWelcomeCan" src={desktopWelcomeCan} />
      <img className="desktopWelcomeIcons" src={desktopWelcomeIcons} />
    </div>

    <div className="btns">
      <div
        className="btn"
        onClick={() =>
          document
            .getElementById("section3")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        {" "}
        UPOZNAJ UMETNIKE{" "}
      </div>
      <div
        className="btn"
        onClick={() =>
          document
            .getElementById("section4")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        {" "}
        GLASAJ{" "}
      </div>
    </div>
  </div>
);

export default WelcomePart;
